@import url('https://fonts.googleapis.com/css?family=Montserrat');
* {
    font-family: montserrat, sans-serif;
    text-rendering: optimizeLegibility; /* For safari */
}
body,html {
    margin: 0;
    padding: 0;
}
a {    /* Removes default styling for links, and React Links */
    text-decoration: none;
    color: inherit;
}
/*–––––––––––––––––––––––––––––––––––––––––––––––– */

/* VARIABLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
:root { /* Defining dark mode variables */
    --text: white;
    --bgColor1: #1a1a1a;
    --bgColor2: #282828;
    --navBar: #202020;
}

body {
    background-color: var(--bgColor1);
    color: var(--text);
}
html { /* For smooth scrolling to sections */
    scroll-behavior: smooth;
}

/* BACKGROUND ANIMATION
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.bg {
    animation: slide 3s ease-in-out infinite alternate;
    background-image: linear-gradient(-60deg, var(--bgColor1) 50%, var(--bgColor2) 50%);
    bottom: 0;
    left: -50%;
    opacity: .5;
    position: fixed;
    right: -50%;
    top: 0;
    z-index: -1;
}
.bg2 {
    animation-direction: alternate-reverse;
    animation-duration: 7s;
}
.bg3 {
    animation-duration: 8s;
}
.content {
    background-color: rgba(255,255,255,.8);
    border-radius: .25em;
    box-shadow: 0 0 .25em rgba(0,0,0,.25);
    box-sizing:border-box;
    left: 50%;
    padding: 10vmin;
    position: fixed;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
}
@keyframes slide {
    0% {
        transform: translateX(-25%);
    }
    100% {
        transform: translateX(25%);
    }
}

/* LANDING PAGE
–––––––––––––––––––––––––––––––––––––––––––––––––– */
#landing-page {
    user-select: none;
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    justify-items: center;
    text-align: center;
    height: 100vh;
    padding-right: 10px;
    padding-left: 10px;
}

.name {
    color: #ff4d5a;
}
h1 {
    font-weight: 300;
    align-self: end;
}
#intro-line1, #intro-line2 {
    display: inline-block;
    /* animation properties */
    animation-duration: 1.5s;
    animation-iteration-count: 1;

    animation-timing-function: cubic-bezier();
    -webkit-animation-timing-function: cubic-bezier();

    -webkit-animation-duration: 0.9s;
    -ms-animation-duration: 1.5s;
    -moz-animation-duration: 1.5s;
}
#intro-line1 {animation-name: slide-right}
#intro-line2 {animation-name: slide-left}
@keyframes slide-right {
    0% {
        opacity: 0%;
        transform: translateX(-100px);
    }
    100% {
        opacity: 100%;
        transform: translateX(0px);
    }
}
@keyframes slide-left {
    0% {
        opacity: 0%;
        transform: translateX(100px);
    }
    100% {
        opacity: 100%;
        transform: translateX(0px);
    }
}
#view-work { /* View my work button */
    cursor: pointer;
    border: 2px solid;
    border-radius: 2px;
    padding: 5px 20px 5px 20px;
    align-self: start;
}
#view-work:hover {
	box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
	transform: scale(1);
	animation: pulse 1.6s infinite;
}
@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 77, 90, 0.7);
	}
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 15px rgba(255, 77, 90, 0);
	}
	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 77, 90, 0);
	}
}

/* SWITCH
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.switch input {
    position: absolute;
    opacity: 0;
}
.switch {
    cursor: pointer;
    display: inline-block;
    font-size: 20px;
    height: 1em;
    width: 2em;
    background: #BDB9A6;
    border-radius: 1em;
}
.switch div {
    height: 1em;
    width: 1em;
    border-radius: 1em;
    background: #FFF;
    box-shadow: 0 0.1em 0.3em rgba(0,0,0,0.3);
    transition: all 300ms;
}
.switch input:checked + div {
    transform: translate3d(100%, 0, 0);
}
.switch input:checked + #nav-bar {
    color: red;
}

/* NAVBAR
–––––––––––––––––––––––––––––––––––––––––––––––––– */
#nav-bar {
    z-index: 1;
    list-style-type: none; /* Disable ul dots */
    display: grid;
    grid-template-columns: 1fr repeat(4, 100px);
    justify-items: center;
    justify-content: end;
    padding-left: 15px;
    padding-right: 15px;
    height: 59px;
    margin: 0;
    background-color: var(--navBar);
    position: sticky;
    top: 0;
    align-items: center;
}
.nav-links {
    cursor: pointer;
    font-weight: 900;
    transition: color 0.3s;
}
.nav-links:hover {
    color: #ff4d5a;
}
.switch {
    justify-self: start;
}

/* ALL PAGES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
#about-page, #project-page, #contact-page {
    margin: 0 71.5px 0 71.5px;
    padding: 15px;
}
#about-page, #project-page, #contact-page {padding-top: 50px} /* Fixes scroll positioning */
h2 {
    user-select: none;
    font-size: 2rem;
    align-self: center;
    justify-self: center;
    text-align: center;
}

/* ABOUT PAGE */
#about-page {
    display: grid;
    gap: 20px;
}
#about-info {
    display: grid;
    text-align: center;
    width: 350px;
    align-self: center;
    justify-self: center;
}
#tech-stack {
    user-select: none;
    justify-self: center;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(4, 100px);
    grid-template-rows: repeat(3, 100px);
}
#tech-stack > div {
    display: grid;
    grid-template-rows: 1.2fr 0.4fr;
    gap: 10px;
    justify-items: center;
    align-items: center;

    font-family: Helvetica, sans-serif;
    font-size: 10.4px;
    line-height: 13.52px;
    text-align: center;
    text-transform: uppercase;
    border: 1px dotted white;
    border-radius: 2px;

    padding: 15px;

    transition: background-color 0.2s;
}
#tech-stack > div:hover {
    background-color: white;
    color: black;
    font-weight: bolder;
}
i {font-size: 50px} /* Size of devicon icons */

/* PROJECTS
–––––––––––––––––––––––––––––––––––––––––––––––––– */
#project-page {
    display: grid;
    gap: 80px;
    padding-right: 0;
    /* padding-left: 0; */
    margin-right: 0;
    /* margin-left: 0; */
}
.projects {
    display: grid;
    grid-template-rows: 1fr 4fr;
    grid-template-columns: repeat(2, 1fr);
}
.project-title {
    user-select: none;
    grid-row: 1/2;
    grid-column: 1/2;
    align-self: center;
    font-size: 1.5em;
    color:aquamarine;
}
.img-container {
    z-index: -1;
    grid-row: 1/3;
    grid-column: 2/3;
    justify-self: end;
    height: 100%;
    width: 120%;
    background-color: var(--navBar);
    display: grid;
    align-items: center;
    justify-items: center;
}
.project-img {
    z-index: -1;
    height: auto;
    width: 80%;
    opacity: 80%;
}
.project-desc {
    grid-row: 2/3;
    grid-column: 1/2;
}
.para3 {
    border-top: solid 1px grey;
    padding-top: 20px;
}
.project-btn {
    width: 150px;
    color: #0ebf83;
    border: 2px solid #0ebf83;
    border-radius: 2px;
    padding: 5px 20px 5px 20px;
    text-align: center;
    transform: perspective(1px) translateZ(0);
    transition: color 0.5s;
}
  .project-btn:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #0ebf83;
    transform: scaleY(0);
    transform-origin: 50% 100%;
    transition: transform 0.5s ease-out;
  }
  .project-btn:hover, .project-btn:focus, .project-btn:active {
    color: white
  }
  .project-btn:hover:before, .project-btn:focus:before, .project-btn:active:before {
    transform: scaleY(1);
    transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  }

/* CONTACTS
–––––––––––––––––––––––––––––––––––––––––––––––––– */
#contact-page {
    display: grid;
    grid-template-rows: 1fr 1fr;
    margin: 0;
    padding-bottom: 60px;
}
#logos {
    justify-self: center;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(3, 100px);
    grid-template-rows: 100px;
}
#logos > a {
    display: grid;
    grid-template-rows: 1.2fr 0.4fr;
    gap: 10px;
    cursor: pointer;
    justify-items: center;
    align-items: center;

    font-family: Helvetica, sans-serif;
    font-size: 10.4px;
    line-height: 13.52px;
    text-align: center;
    text-transform: uppercase;
    border: 1px dotted white;
    border-radius: 2px;

    padding: 15px;

    transition: background-color 0.2s;
}
#logos > a:hover {
    background-color: white;
    color: black;
    font-weight: bolder;
}

/* FOOTER
–––––––––––––––––––––––––––––––––––––––––––––––––– */
#footer {
    display: grid;
    background-color: #0ebf83;
    color: rgb(0, 0, 0);
    font-size: 1.1em;
    font-weight: 700;
    text-align: center;
    align-content: center;
}

/* MEDIA QUERIES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@media (min-width: 0px) and (max-width: 770px) { /* Removing margin for mobile devices */
    #about-page, #contact-page, #project-page {font-weight: 100; line-height: 1.2em}
    #about-info {width: 340px}
    #project-page {margin-left: 0px}
    #tech-stack {
        grid-template-columns: repeat(3, 100px);
        grid-template-rows: repeat(4, 100px);
    }
    #about-page {
        margin-left: 0px;
        margin-right: 0px;
        padding: 8px;
    }
    #about-page > h2 {
        padding-top: 20px;
    }
}
@media (min-width: 770px) and (max-width: 8000px) {
    #project-page {font-weight: 500; line-height: 1.5em}
    #about-info {width: 500px}
}

/* All general media queries */
@media (min-width: 0px) and (max-width: 370px) {
    #landing-page {font-size: 1em}
    #nav-bar, #footer {
        font-size: 0.7em;
        height: 32px;
    }
    #nav-bar {grid-template-columns: 1fr repeat(4, 50px)}
    #project-page {gap: 60px}
    .projects {height: 190px; padding-bottom: 170px;}
    .project-title {font-size: 1.0em}
    .project-desc, #intro1  {font-size: 0.8em}
    .img-container {width: 100%}
}
@media (min-width: 370px) and (max-width: 615px) {
    #landing-page {font-size: 1.1em}
    #nav-bar, #footer {
        font-size: 0.8em;
        height: 38px;
    }
    #nav-bar {grid-template-columns: 1fr repeat(4, 70px)}
    #project-page {gap: 70px}
    .projects {height: 280px; padding-bottom: 80px;}
    .project-title {font-size: 1.1em}
    .project-desc, #intro1  {font-size: 0.8em}
    .img-container {width: 100%}
}
@media (min-width: 615px) and (max-width: 770px) {
    #landing-page {font-size: 1.2em}
    #nav-bar, #footer {
        font-size: 0.9em;
        height: 41px;
    }
    #nav-bar {grid-template-columns: 1fr repeat(4, 80px)}
    #project-page {gap: 80px}
    .projects {height: 350px}
    .project-title {font-size: 1.2em}
    .project-desc, #intro1  {font-size: 0.8em}
    .img-container {width: 100%}
}
@media (min-width: 770px) and (max-width: 1500px) {
    #landing-page {font-size: 1.6em}
    #nav-bar, #footer {
        font-size: 1em;
        height: 47px;
    }
    #nav-bar {grid-template-columns: 1fr repeat(4, 90px)}
    #project-page {gap: 100px}
    .projects {height: 512px}
    .project-title {font-size: 1.7em}
    .project-desc, #intro1  {font-size: 0.9em}
    .img-container {width: 100%}
}
@media (min-width: 1500px) and (max-width: 8000px) {
    #landing-page {font-size: 1.9em}
    #nav-bar, #footer {
        font-size: 1.2em;
        height: 59px;
    }
    #nav-bar {grid-template-columns: 1fr repeat(4, 120px)}
    #project-page {
        gap: 140px;
        margin-left: 150px;
        margin-right: 150px;
    }
    .projects {height: 700px}
    .project-title {font-size: 2.2em}
    .project-desc, #intro1  {font-size: 1.15em}
    .project-desc {max-width: 600px}
    .img-container {width: 140%}
    .project-img { 
        max-width: 940px;
        max-height: auto;
    }
}